import { useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { type AssociationResponse, getAssociationsKey } from '../../RMS/rms.services';

const useStoreToSalesChannelTransformer = () => {
  const appId = useSelector((state: AppState) => state.currentApp.AppId) ?? '';

  const queryClient = useQueryClient();

  const associations = queryClient.getQueryData<AssociationResponse>([getAssociationsKey, appId]);

  return useMemo(() => {
    const associationSalesChannels: Array<{
      salesChannelId: string;
      salesChannelName: string;
      salesChannelType?: string;
      storeId: number;
    }> = [];

    associations?.data?.orgHierarchy?.properties?.forEach((property) => {
      property.storefronts.forEach((storefront) => {
        if (storefront.fieldAssociations?.flipdish?.storeId) {
          associationSalesChannels.push({
            salesChannelId: storefront.code,
            salesChannelName: storefront.name,
            salesChannelType: storefront.type,
            storeId: Number(storefront.fieldAssociations.flipdish.storeId),
          });
        }
      });
    });
    return associationSalesChannels;
  }, [associations]);
};

export default useStoreToSalesChannelTransformer;
