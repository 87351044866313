import React, { useCallback } from 'react';

import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import Skeleton from 'react-loading-skeleton';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import { FormikInputField } from '@fd/ui/molecules';

import { validateOpeningHours } from '../validation';

const FieldContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const StyledLabel = styled(InputLabel)(({ theme }) => ({
  minWidth: '100px',
  paddingRight: theme.spacing(2),
}));

const StyledFormikField = styled(FormikInputField)({
  flex: 1,
});

type OpeningHoursFieldProps = {
  name: string;
  label?: string;
  showLabel?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
};

const OpeningHoursField = ({
  name,
  label,
  showLabel = true,
  disabled = false,
  isLoading = false,
  placeholder,
}: OpeningHoursFieldProps) => {
  const translate = useSelector((state: AppState) => getTranslate(state.locale));

  const validateFunction = useCallback(
    (value: string) => validateOpeningHours(value, translate),
    [translate]
  );

  return (
    <FieldContainer>
      {showLabel && label && (
        <Grid item xs={2}>
          <StyledLabel htmlFor={name}>
            {isLoading ? <Skeleton width={50} /> : label.slice(0, 3)}
          </StyledLabel>
        </Grid>
      )}
      <Grid item xs={10}>
        <StyledFormikField
          fdKey={name}
          name={name}
          disabled={disabled}
          fullWidth
          placeholder={placeholder}
          validate={validateFunction}
        />
      </Grid>
    </FieldContainer>
  );
};

export default OpeningHoursField;
