import React, { useMemo } from 'react';

import { OpeningHour, OpeningHourDispatchTypesEnum } from '@flipdish/orgmanagement';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Form, Formik } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button } from '@fd/ui/atoms';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import Legend from '../OpeningHours/components/Legend';
import WeekdayFields from './components/WeekdayFields';
import {
  convertDailyOpeningHoursTimeArraysToStrings,
  defaultDayValues,
  mapStringOpeningHoursToOpeningHours,
  mapTimeSlotsToDailyOpeningHours,
} from './utils';

const FieldsGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '& > div': {
      paddingRight: theme.spacing(2),
      '&:last-child': {
        paddingRight: 0,
      },
    },
  },
}));

const StyledLegendContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1.5),
  },
}));

const StyledSaveContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(1),
}));

const StyledSaveButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: 'fit-content',
  },
}));

type OpeningHoursFormProps = {
  openingHours: OpeningHour[];
  isLoading: boolean;
  isSaving: boolean;
  updateOpeningHours: (hours: OpeningHour[]) => Promise<void>;
};

type Props = OpeningHoursFormProps & MappedState;
const OpeningHoursForm = ({
  openingHours,
  translate,
  isLoading,
  isSaving,
  updateOpeningHours,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const openingHoursFromApi = useMemo(
    () =>
      convertDailyOpeningHoursTimeArraysToStrings(
        mapTimeSlotsToDailyOpeningHours(openingHours || [], translate)
      ),
    [openingHours]
  );
  return (
    <PaperContainer>
      <Formik
        enableReinitialize
        initialValues={
          openingHoursFromApi?.Collection?.['Monday'] ? openingHoursFromApi : defaultDayValues
        }
        onSubmit={async (values) => {
          const arrayFormat = mapStringOpeningHoursToOpeningHours(values, translate);
          await updateOpeningHours(arrayFormat);
        }}
      >
        <Form>
          <FieldsGrid container>
            <StyledLegendContainer item xs={12} md={8}>
              <Legend />
            </StyledLegendContainer>
            <StyledSaveContainer item container xs={12} md={4}>
              <StyledSaveButton
                disabled={isSaving || isLoading}
                fullWidth={isMobile}
                fdKey="submit"
                type="submit"
                name="Save"
              >
                {translate('Save')}
              </StyledSaveButton>
            </StyledSaveContainer>

            <Grid item xs={12} md={6}>
              <WeekdayFields
                title="Opening_hours_collection_title"
                type={OpeningHourDispatchTypesEnum.Collection}
                isLoading={isLoading}
                isSaving={isSaving}
                showLabels={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WeekdayFields
                title="Opening_hours_delivery_title"
                type={OpeningHourDispatchTypesEnum.Delivery}
                isLoading={isLoading}
                isSaving={isSaving}
                showLabels={isMobile}
              />
            </Grid>
          </FieldsGrid>
        </Form>
      </Formik>
    </PaperContainer>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state),
});

export default connect(mapStateToProps)(OpeningHoursForm);
