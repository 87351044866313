import {
  OpeningHour,
  OpeningHourDayEnum,
  OpeningHourDispatchTypesEnum,
  OpeningHourTypeEnum,
} from '@flipdish/orgmanagement';
import { TranslateFunction } from 'react-localize-redux';

type DailyOpeningHoursTimeStrings = {
  Collection: { [key: string]: string };
  Delivery: { [key: string]: string };
};

type DailyOpeningHoursTimeArrays = {
  Collection: { [key: string]: string[] };
  Delivery: { [key: string]: string[] };
};

export const mapTimeSlotsToDailyOpeningHours = (
  timeSlot: OpeningHour[],
  translate: TranslateFunction
): DailyOpeningHoursTimeArrays => {
  if (!timeSlot) return { Collection: {}, Delivery: {} };
  return timeSlot.reduce(
    (acc, slot) => {
      const { day, open, close, dispatchTypes, type } = slot;
      let time: string;

      if (type === OpeningHourTypeEnum.AllDayOpen) {
        time = (translate('All_day') as string)?.toLowerCase();
      } else if (type === OpeningHourTypeEnum.AllDayClose) {
        time = (translate('Closed') as string)?.toLowerCase();
      } else {
        time = `${open}-${close}`;
      }

      if (dispatchTypes?.length === 0) {
        // If no dispatch types, add to both collection and delivery
        if (!acc.Collection[day]) acc.Collection[day] = [];
        if (!acc.Delivery[day]) acc.Delivery[day] = [];
        acc.Collection[day].push(time);
        acc.Delivery[day].push(time);
      } else {
        // Add to each specified dispatch type
        dispatchTypes?.forEach((type) => {
          if (!acc[type]) acc[type] = {}; // Initialize type if not present
          if (!acc[type][day]) acc[type][day] = [];
          acc[type][day].push(time);
        });
      }

      return acc;
    },
    {
      Collection: {}, // Grouped by day for collection
      Delivery: {}, // Grouped by day for delivery
    }
  );
};

export function convertDailyOpeningHoursTimeArraysToStrings(
  obj: DailyOpeningHoursTimeArrays
): DailyOpeningHoursTimeStrings {
  const result: DailyOpeningHoursTimeStrings = {
    Collection: {},
    Delivery: {},
  };

  Object.entries(obj).forEach(([key, value]) => {
    if (key === 'Collection' || key === 'Delivery') {
      result[key as keyof DailyOpeningHoursTimeStrings] = Object.entries(value).reduce(
        (acc, [day, times]) => {
          acc[day] = Array.isArray(times) ? times.join(', ') : times;
          return acc;
        },
        {} as { [key: string]: string }
      );
    }
  });

  return result;
}

// Helper function to create an OpeningHour object
const createOpeningHour = (
  day: string,
  dispatchType: string,
  type: OpeningHourTypeEnum,
  open = '',
  close = ''
): OpeningHour => ({
  day: day as OpeningHourDayEnum,
  open,
  close,
  type,
  dispatchTypes: [dispatchType as OpeningHourDispatchTypesEnum],
});

// Helper function to handle a single time range
export const processTimeRange = (
  timeRange: string,
  day: string,
  dispatchType: string,
  translate: TranslateFunction
) => {
  const lowerTimeRange = timeRange?.toLowerCase()?.trim();
  // Handle empty string as closed
  if (
    !timeRange ||
    timeRange === '' ||
    lowerTimeRange === (translate('Closed') as string)?.toLowerCase()
  ) {
    return createOpeningHour(day, dispatchType, OpeningHourTypeEnum.AllDayClose);
  }

  if (lowerTimeRange === (translate('All_day') as string)?.toLowerCase()) {
    return createOpeningHour(day, dispatchType, OpeningHourTypeEnum.AllDayOpen);
  }

  const [open, close] = timeRange.split('-').map((time) => time?.trim());

  if (!open || !close) {
    return createOpeningHour(day, dispatchType, OpeningHourTypeEnum.AllDayClose);
  }

  return createOpeningHour(day, dispatchType, OpeningHourTypeEnum.TimeSlot, open, close);
};

export const mapStringOpeningHoursToOpeningHours = (
  dailyOpeningHoursWithTimeStrings: DailyOpeningHoursTimeStrings,
  translate: TranslateFunction
) => {
  const result: OpeningHour[] = [];

  for (const [dispatchType, days] of Object.entries(dailyOpeningHoursWithTimeStrings)) {
    for (const [day, timeRanges] of Object.entries(days)) {
      const ranges = timeRanges
        ? timeRanges.split(',').map((range) => range.trim() || (translate('Closed') as string))
        : [''];
      ranges.forEach((timeRange) => {
        const openingHour = processTimeRange(timeRange, day, dispatchType, translate);
        if (openingHour) {
          result.push(openingHour);
        }
      });
    }
  }

  return result;
};

export const defaultDayValues = {
  Collection: {
    [OpeningHourDayEnum.Monday]: '',
    [OpeningHourDayEnum.Tuesday]: '',
    [OpeningHourDayEnum.Wednesday]: '',
    [OpeningHourDayEnum.Thursday]: '',
    [OpeningHourDayEnum.Friday]: '',
    [OpeningHourDayEnum.Saturday]: '',
    [OpeningHourDayEnum.Sunday]: '',
  },
  Delivery: {
    [OpeningHourDayEnum.Monday]: '',
    [OpeningHourDayEnum.Tuesday]: '',
    [OpeningHourDayEnum.Wednesday]: '',
    [OpeningHourDayEnum.Thursday]: '',
    [OpeningHourDayEnum.Friday]: '',
    [OpeningHourDayEnum.Saturday]: '',
    [OpeningHourDayEnum.Sunday]: '',
  },
};
