import React, { useCallback, useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { StoreGroupBase } from 'overrides/@flipdish/api-client-typescript/api';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';

import { FIVE_MINUTES } from '../../../helpers/timeConstants';
import { notifyError, NotifyProps, notifySaved } from '../../../layouts/Notify/actions';
import { getSalesChannelGroupKey, storeGroupService } from '../../../services/storegroup.service';
import { getSalesChannelType } from '../../../ui/utils/generateSalesChannelsTypes';
import { findStoreGroupId } from '../utils/findStoreGroupById';
import useStoreToSalesChannelTransformer from '../utils/useStoreToSalesChannelTransformer';
import SalesChannelGroupForm, { FormValues } from './components/SalesChannelGroupForm';
import { StoreHeadersEnhanced } from './SalesChannelGroupDetails';

type Props = MappedState & MappedDispatch;

const AddSalesChannelGroup = ({
  currentBrandId,
  translate,
  dispatchNotifySaved,
  dispatchNotifyError,
}: Props) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { data: salesChannelGroupsData } = useQuery({
    queryKey: [getSalesChannelGroupKey, currentBrandId],
    queryFn: () => storeGroupService.getAllForApp({ appId: currentBrandId ?? '' }),
    staleTime: FIVE_MINUTES,
  });

  const storeIdAssociations = useStoreToSalesChannelTransformer();

  const options = useMemo(() => {
    return storeIdAssociations.map((association) => {
      const storeGroupId = findStoreGroupId({
        storeId: association?.storeId,
        salesChannelGroupsData,
      });

      return {
        label: `${association?.salesChannelName} ${getSalesChannelType(association.salesChannelType ?? '')}`,
        value: association?.storeId,
        scId: association?.salesChannelId,
        storeGroupId,
        isNonClearable: true,
        salesChannelType: association?.salesChannelType,
      };
    });
  }, [storeIdAssociations]);

  const { mutateAsync: mutateAsyncCreate } = useMutation({
    mutationFn: (data: { storeGroup: StoreGroupBase }) => {
      return storeGroupService.create(currentBrandId ?? '', data.storeGroup);
    },
  });

  const { mutateAsync: mutateAsyncAssignStores } = useMutation({
    mutationFn: (data: {
      brandId: string;
      storeGroupId: number;
      storeHeaders: StoreHeadersEnhanced;
    }) => {
      const storeIds = data.storeHeaders.map((s) => s.StoreId);
      return storeGroupService.assignStores(data.brandId, data.storeGroupId, storeIds);
    },
  });

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      let assignSuccess;
      try {
        const createResponse = await mutateAsyncCreate({
          storeGroup: { Name: formValues.name, Currency: formValues.currency?.value },
        });

        const storeHeaders: StoreHeadersEnhanced | undefined = formValues.salesChannels?.map(
          (sc) => {
            return {
              StoreId: sc.value,
              Name: sc.label,
              Category: null as any,
              fromStoreGroupId: sc.storeGroupId,
            };
          }
        );

        if (createResponse.StoreGroupId && storeHeaders?.length) {
          try {
            await mutateAsyncAssignStores({
              brandId: currentBrandId ?? '',
              storeGroupId: createResponse.StoreGroupId,
              storeHeaders,
            });
            assignSuccess = true;
          } catch (e) {
            dispatchNotifyError({ message: e.message, translate: true });
            // Rollback: Delete the store group if assignment of sales channels fails
            await storeGroupService.remove(createResponse.StoreGroupId);
          }
        }

        if (createResponse && assignSuccess) {
          history.push(`/${currentBrandId}/settings/sales-channel-groups`);
          dispatchNotifySaved();
        }
      } catch (e) {
        dispatchNotifyError({ message: e.message, translate: true });
      } finally {
        queryClient.invalidateQueries({
          queryKey: [getSalesChannelGroupKey, currentBrandId],
        });
      }
    },
    [currentBrandId, getSalesChannelGroupKey]
  );

  return (
    <PageLayout
      documentTitle="Add_sales_channel_group"
      title={translate('Add_sales_channel_group')}
      userPermissions={'Owner'}
      toParent={`/${currentBrandId}/settings/sales-channel-groups/`}
      strictToParent
    >
      <SalesChannelGroupForm
        options={options}
        onSubmit={(formValues) => handleSubmit(formValues)}
      />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    currentBrandId: state.currentApp.AppId,
    translate: getTranslate(state.locale),
  };
};
type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSalesChannelGroup);
