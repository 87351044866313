import React, { useMemo } from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

import ErrorComponent from '@fd/ui/ErrorComponent';
import PageLayout from '@fd/ui/Layout';

import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';
import PropertyFilter from './PropertyFilter';
import RMSIframe from './RMSIframe';

const StyledGridItem = styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  paddingLeft: 0,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
}));

type RMSIframeProps = {
  module: RMSModules;
  url: string;
  subscription: RMSSubscriptions;
  title?: string | JSX.Element;
  includePropertyIdInUrl?: boolean;
  allowAllProperties?: boolean;
  allowFranchisorLevel?: boolean;
  pageLayoutEnabled?: boolean;
  toParent?: string;
  propertyIdFromUrl?: boolean;
  salesChannelIdFromUrl?: boolean;
  noMaxWidth?: boolean;
};

const RMSPage = ({
  url,
  module,
  title,
  includePropertyIdInUrl,
  allowAllProperties,
  allowFranchisorLevel,
  subscription,
  pageLayoutEnabled = true,
  toParent = './',
  propertyIdFromUrl = false,
  salesChannelIdFromUrl = false,
  noMaxWidth = false,
}: RMSIframeProps) => {
  const params = useParams<{ channelId?: string; propertyId?: string; appId?: string }>();
  const channelId = params?.channelId;
  const propertyId = params?.propertyId;
  const appId = params?.appId;
  const isOrgApiPropertyId = !!propertyId?.startsWith('p');

  const {
    data: commonData,
    isLoading: isLoadingCommonData,
    error: commonDataError,
  } = useQueryRMSCommonDataHook(appId || '');

  // rms apis only work with RMS property id
  const rmsPropertyId =
    (isOrgApiPropertyId
      ? commonData?.associations?.orgHierarchy?.properties?.find(
          (property) => property.code === propertyId
        )?.id
      : propertyId) || propertyId;

  // iframes only work with RMS ids
  const rmsSalesChannelId = commonData?.associations?.orgHierarchy?.properties
    ?.find((property) => property.id === rmsPropertyId)
    ?.storefronts?.find((storefront) => storefront.code === channelId)?.id;

  const Content = useMemo(() => {
    if (isLoadingCommonData) {
      return <CircularProgress />;
    }

    if (commonDataError) {
      return <ErrorComponent title="Something_went_wrong" />;
    }

    return (
      <>
        {includePropertyIdInUrl && (
          <Grid container>
            <StyledGridItem item xs={12} md={6}>
              <PropertyFilter
                subscription={subscription}
                includeAllPropertiesOption={allowAllProperties}
                allowEmptyPropertiesInUrl={allowFranchisorLevel || allowAllProperties}
              />
            </StyledGridItem>
          </Grid>
        )}
        <RMSIframe
          module={module}
          url={url}
          includePropertyIdInUrl={includePropertyIdInUrl}
          allowEmptyPropertiesInUrl={allowAllProperties || allowFranchisorLevel}
          storefrontId={salesChannelIdFromUrl ? rmsSalesChannelId : undefined}
          propertyIdfromUrl={propertyIdFromUrl ? rmsPropertyId : undefined}
        />
      </>
    );
  }, [
    isLoadingCommonData,
    commonDataError,
    includePropertyIdInUrl,
    subscription,
    allowAllProperties,
    allowFranchisorLevel,
    module,
    url,
    channelId,
    propertyIdFromUrl,
    rmsPropertyId,
    salesChannelIdFromUrl,
  ]);

  return pageLayoutEnabled ? (
    <PageLayout title={title} strictToParent toParent={toParent} noMaxWidth={noMaxWidth}>
      {Content}
    </PageLayout>
  ) : (
    Content
  );
};

export default RMSPage;
