import React, { type CSSProperties, useState } from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import {
  SalesChannelType,
  useQueryRMSSalesChannels,
} from '@fd/customHooks/useQueryRMSSalesChannels';
import type { SalesChannel } from '@flipdish/orgmanagement';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import EmptyComponent from '@fd/ui/EmptyComponent';
import ErrorComponent from '@fd/ui/ErrorComponent';

import EmptyIcon from '../../../assets/images/illust_store.svg';
import { notifyError, notifySaved } from '../../../layouts/Notify/actions';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import PropertyFilter from '../components/PropertyFilter';
import { getAssociationsKey } from '../rms.services';
import { cloneSalesChannel, getSalesChannelsKey } from '../salesChannels.services';
import CreateNewSalesChannelDialog from './CreateNewSalesChannelDialog';
import SalesChannelIcon from './SalesChannelIcon';
import { getIsFlipdishChannel } from './utils';
// #region styles
const StyledContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));

const StyledCard = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(2),
  border: '1px solid #ddd',
  borderRadius: '8px',
  width: '300px',
  minHeight: '120px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const StyledAddIcon = styled(AddOutlinedIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

const StyledChannelTag = styled(Typography)<{
  ownerState: { backgroundColor: CSSProperties['backgroundColor']; color?: CSSProperties['color'] };
}>(({ theme, ownerState }) => ({
  display: 'inline-flex',
  padding: theme.spacing(0.5, 1),
  borderRadius: '4px',
  backgroundColor: ownerState.backgroundColor,
  color: ownerState?.color ? ownerState.color : '#000',
  fontSize: '12px',
  fontWeight: 400,
  marginTop: theme.spacing(1),
  lineHeight: theme.spacing(2),
}));

const StyledGridContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const StyledIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

//#endregion

const Content = ({
  isLoadingSalesChannels,
  salesChannelError,
  salesChannels,
  onChannelClick,
  isFlipdishStaff,
}: {
  salesChannels?: SalesChannel[];
  isLoadingSalesChannels: boolean;
  salesChannelError: Error | null;
  onChannelClick: (channel: SalesChannel) => void;
  isFlipdishStaff: boolean;
}) => {
  if (isLoadingSalesChannels) {
    return <CircularProgress />;
  }

  if (salesChannelError) {
    return <ErrorComponent title="Something_went_wrong" />;
  }

  if (!salesChannels || salesChannels.length === 0) {
    return (
      <EmptyComponent
        title="No_sales_channels_found"
        subtitle="Add_sales_channels_to_your_property"
        icon={EmptyIcon}
        noLink
      />
    );
  }

  const handleKeyDown = (event, salesChannel: SalesChannel) => {
    if (event.key === 'Enter') {
      onChannelClick(salesChannel);
    }
  };

  const buildChannelTag = (salesChannel: SalesChannel) => {
    const tags: JSX.Element[] = [];

    // @ts-expect-error api types are wrong
    if (salesChannel?.hierarchy?.type === 'Primary') {
      tags.push(
        <Grid item key="primary">
          <StyledChannelTag ownerState={{ backgroundColor: '#0B74D7', color: '#FFF' }}>
            <Translate id="Primary_channel" />
          </StyledChannelTag>
        </Grid>
      );
      // @ts-expect-error api types are wrong
    } else if (salesChannel?.hierarchy?.type === 'Linked') {
      tags.push(
        <Grid item key="linked">
          <StyledChannelTag ownerState={{ backgroundColor: '#EEEBE9' }}>
            <Translate id="Linked_channel" />
          </StyledChannelTag>
        </Grid>
      );
    }

    if (!getIsFlipdishChannel(salesChannel?.type as SalesChannelType)) {
      return tags;
    }

    if (salesChannel?.isArchived) {
      tags.push(
        <Grid item key="archived">
          <StyledChannelTag ownerState={{ backgroundColor: '#EEEBE9' }}>
            <Translate id="Archived" />
          </StyledChannelTag>
        </Grid>
      );
    }
    if (salesChannel?.isPublished && !salesChannel?.isArchived) {
      tags.push(
        <Grid item key="published">
          <StyledChannelTag ownerState={{ backgroundColor: '#00B654', color: '#FFF' }}>
            <Translate id="Published" />
          </StyledChannelTag>
        </Grid>
      );
    }
    if (!salesChannel?.isPublished && !salesChannel?.isArchived) {
      tags.push(
        <Grid item key="Unpublished">
          <StyledChannelTag ownerState={{ backgroundColor: '#EEEBE9' }}>
            <Translate id="Unpublished" />
          </StyledChannelTag>
        </Grid>
      );
    }

    return tags;
  };

  const salesChannelsFiltered = isFlipdishStaff
    ? salesChannels
    : salesChannels.filter((sc) => !sc.isArchived);

  return (
    <Grid container gap={2}>
      {salesChannelsFiltered.map((salesChannel) => (
        <StyledCard
          tabIndex={0}
          key={salesChannel?.salesChannelId}
          onClick={() => onChannelClick(salesChannel)}
          onKeyDown={(e) => handleKeyDown(e, salesChannel)}
          data-fd={salesChannel?.salesChannelId}
        >
          <Grid container>
            <Grid item xs={3}>
              <StyledIconWrapper>
                <SalesChannelIcon type={salesChannel?.type} />
              </StyledIconWrapper>
            </Grid>
            <StyledGridContainer xs={9}>
              <Typography variant="h6">{salesChannel.name}</Typography>
              <Typography variant="caption" color="textSecondary">
                {salesChannel.type}
              </Typography>
              <>{buildChannelTag(salesChannel)}</>
            </StyledGridContainer>
          </Grid>
        </StyledCard>
      ))}
    </Grid>
  );
};

type SalesChannelListProps = {
  filterByType?: SalesChannelType[];
  onChannelClick: (channel) => void;
  hideFilter?: boolean;
} & MappedState &
  MappedDispatch;
const SalesChannelList = (props: SalesChannelListProps) => {
  const {
    appId,
    filterByType = [],
    hideFilter,
    onChannelClick,
    selectedPropertyId,
    translate,
    orgId,
    isFlipdishStaff,
    dispatchNotifyError,
    dispatchNotifySuccess,
  } = props;
  const {
    data: commonData,
    isLoading: isLoadingCommonData,
    error: commonDataError,
  } = useQueryRMSCommonDataHook(appId ?? '');
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const params = useParams<{ propertyId: string }>();

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (values: {
      orgId: string;
      propertyId: string;
      salesChannelId: string;
      type: SalesChannelType;
    }) => cloneSalesChannel(values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getAssociationsKey] });
      queryClient.invalidateQueries({ queryKey: [getSalesChannelsKey] });
      dispatchNotifySuccess();
      handleDialogClose();
    },
    onError: (error: Error) => {
      dispatchNotifyError(error);
    },
  });

  const propertyId = params.propertyId || selectedPropertyId;

  const orgPropertyId =
    (propertyId?.startsWith('p')
      ? propertyId
      : commonData?.associations?.orgHierarchy?.properties?.find(
          (property) => property.id === propertyId
        )?.code) || propertyId;

  const {
    isLoading: isLoadingSalesChannels,
    error: salesChannelError,
    data: salesChannels,
  } = useQueryRMSSalesChannels({
    orgId: orgId ?? '',
    propertyId: orgPropertyId ?? '',
    filterByType,
  });

  let salesChannelType = 'point-of-sale';
  if (pathname.includes('website')) {
    salesChannelType = 'website';
  } else if (pathname.includes('kiosk')) {
    salesChannelType = 'kiosk';
  } else if (pathname.includes('marketplaces')) {
    salesChannelType = 'marketplaces';
  } else if (pathname.includes('mobile-apps')) {
    salesChannelType = 'mobile-apps';
  }

  return (
    <StyledContainer container>
      {!hideFilter && (
        <>
          <Grid item xs={12} md={6}>
            <PropertyFilter />
          </Grid>
          <Grid display={'flex'} justifyContent={'flex-end'} item xs={12} md={6}>
            <StyledButton variant="contained" onClick={() => handleDialogOpen()}>
              <StyledAddIcon />

              {translate('Add_sales_channel')}
            </StyledButton>
          </Grid>
        </>
      )}
      <Grid item xs={12} display="flex" flexWrap="wrap" gap={2} marginTop={!hideFilter ? 3 : 0}>
        <Content
          isLoadingSalesChannels={isLoadingSalesChannels || isLoadingCommonData}
          salesChannelError={salesChannelError || commonDataError}
          salesChannels={salesChannels}
          onChannelClick={onChannelClick}
          isFlipdishStaff={isFlipdishStaff}
        />
      </Grid>
      <CreateNewSalesChannelDialog
        isLoading={isPending}
        open={isDialogOpen}
        onCancel={handleDialogClose}
        onSave={mutateAsync}
        orgPropertyId={orgPropertyId}
        salesChannelTypeForAddUrl={salesChannelType}
      />
    </StyledContainer>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  return (state: AppState) => {
    const getPermissionsSelector = permissionsSelector.hasPermissionFactory(['FlipdishStaff']);

    return {
      appId: state.currentApp.AppId,
      selectedPropertyId: state.rms.selectedProperty?.id,
      translate: getTranslate(state.locale),
      orgId: state.rms.currentOrg?.orgId,
      isFlipdishStaff: getPermissionsSelector(state),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    dispatchNotifyError: (error: Error) => dispatch(notifyError(error)),
    dispatchNotifySuccess: () => dispatch(notifySaved()),
  };
};
export default connect(mapStateToPropsFactory, mapDispatchToProps)(SalesChannelList);
