import React, { ReactElement } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import useQuerySalesChannelsByParams from '@fd/customHooks/useQuerySalesChannelsByParams';
import { GetSalesChannelsByOrgIdAndPropertyId200Response } from '@flipdish/orgmanagement';
import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import QueryBuilderOutlined from '@mui/icons-material/QueryBuilderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingCartOutlined from '@mui/icons-material/ShoppingCartOutlined';
import StoreOutlined from '@mui/icons-material/StoreOutlined';
import TimerOffOutlined from '@mui/icons-material/TimerOffOutlined';
import Warning from '@mui/icons-material/Warning';
import ZoomInMapOutlinedIcon from '@mui/icons-material/ZoomInMapOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';

import EmptyComponent from '@fd/ui/EmptyComponent';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';

import { storePublish } from '../../actions/store.actions';
import preOrderSettingsSVG from '../../assets/images/ico_preorder.svg';
import serviceChargeSVG from '../../assets/images/service-charge.svg';
import { ReactComponent as TableSettingsIcon } from '../../assets/images/tableSettings.svg';
import * as storeConstants from '../../constants/store.constants';
import { useRedirectIfStoresPageHidden } from '../../custom-hooks/useRedirectIfStoresPageHidden';
import { toKebabCase } from '../../helpers/strings';
import { notifyError, notifySaved } from '../../layouts/Notify/actions';
import { getIsOwner } from '../../selectors/app.selector';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../selectors/loading.selector';
import { getSelectedStore } from '../../selectors/store.selector';
import PageLayout from '../../ui/Layout';
import FullWidthContainer from '../../ui/Layout/FullWidthContainer';
import PaperContainer from '../../ui/Layout/PaperContainer';
import { KioskIcon } from '../Kiosks/Components/KioskNestedMenuIcon';
import SalesChannelService, { getSalesChannelsKey } from '../RMS/salesChannels.services';
import { getBackToSalesChannelListUrl } from '../RMS/SalesChannels/utils';
import { RMSModules } from '../RMS/utils/rmsModules';
import PublishStoreButton from './components/PublishStoreButton';
import StoreTitle from './components/StoreTitle';

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);
const initialLoadingErrorSelector = createLoadingErrorSelectorFactory([storeConstants.STORE_LOAD]);

const PreOrderSettingsIcon = () => <img src={preOrderSettingsSVG} style={{ width: '24px' }} />;
const ServiceChargeIcon = () => <img src={serviceChargeSVG} style={{ width: '24px' }} />;

const useStyles = makeStyles((theme: Theme) => ({
  rootItemDiv: {
    width: '100%',
    padding: '0px',
    margin: '0px',
    textTransform: 'none',
    borderRadius: 0,
  },
  item: {
    margin: '0px',
    minHeight: '72px',
  },
  itemIcon: {
    margin: '16px',
    marginLeft: '0',
    padding: '0px',
    minWidth: '0',
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
    },
  },
  zeroPadding: {
    padding: '0px',
  },
  wordWrap: {
    wordWrap: 'break-word',
  },
  title: {
    margin: '0px 0px 16px 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0px',
      display: 'none',
    },
  },
  listItemDanger: {
    color: '#ff426a',
  },
}));

const routeData = (
  linkedSalesChannelId?: string
): Array<{
  route: string;
  icon: ReactElement;
  title: TranslationId;
  description?: TranslationId;
  dataFd: string;
  hidden?: boolean;
  comingSoon?: boolean;
  permissions: string | string[];
  showForSalesChannel?: boolean;
  tagText?: TranslationId;
}> => [
  {
    route: `/general`,
    icon: <StoreOutlined />,
    title: 'Store_general_settings_title',
    description: 'Set_address_location_phone_number_menu_and_timezon',
    dataFd: 'btn-general-settings',
    permissions: 'ViewStores',
  },
  {
    route: `/openinghours`,
    icon: <QueryBuilderOutlined />,
    title: 'Opening_hours_title',
    description: 'Opening_hours_description',
    dataFd: 'btn-opening-hours',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
  {
    route: `/openinghouroverrides`,
    icon: <TimerOffOutlined />,
    title: 'Opening_hour_overrides_title',
    description: 'Opening_hour_overrides_description',

    dataFd: 'btn-opening-hours-overrides',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
  {
    route: `/deliverysettings`,
    icon: <LocalShippingOutlined />,
    title: 'Delivery_settings_title',
    description: 'Set_delivery_zones_and_prices',
    dataFd: 'btn-delivery-settings',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId && !window.location?.pathname?.includes('/kiosk'),
  },
  {
    route: `/collection`,
    icon: <StoreOutlined />,
    title: 'Collection_settings_title',
    description: 'Collection_settings_description',
    dataFd: 'btn-collection-settings',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
  {
    route: `/ordersettings`,
    icon: <ShoppingCartOutlined />,
    title: 'Order_settings_title',
    description: 'Order_settings_description',
    dataFd: 'btn-order-settings',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
  {
    route: `/servicecharge`,
    icon: <ServiceChargeIcon />,
    title: 'Service_charge_title',
    description: 'Service_charge_description',
    dataFd: 'btn-order-servicecharge',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
  {
    route: `/ordernotifications`,
    icon: <NotificationsIcon />,
    title: 'Order_notifications_title',
    description: 'Order_notifications_description',
    dataFd: 'btn-order-notifications',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
  {
    route: `/preordersettings`,
    icon: <PreOrderSettingsIcon />,
    title: 'Pre_order_settings_title',
    dataFd: 'btn-pre-order-config',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
  {
    route: `/kiosksettings`,
    icon: <KioskIcon />,
    title: 'Store_Kiosk_Settings',
    description: 'Configure_your_store_settings_for_any_associated_Kiosks',
    dataFd: 'btn-store-kiosk-settings',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
  {
    route: `/tablesettings`,
    icon: <TableSettingsIcon />,
    title: 'Table_settings_title',
    description: 'Table_settings_button_description',
    dataFd: 'btn-table-settings',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
  {
    route: `/bankingsettings`,
    icon: <AccountBalanceOutlined />,
    title: 'Banking_title',
    description: 'Banking_settings_description',
    dataFd: 'btn-banking',
    permissions: 'ViewStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
  {
    title: 'Order_Ingest',
    description: 'Configure_order_ingest',
    route: `/${toKebabCase(RMSModules.ORDER_INGEST)}`,
    icon: <ZoomInMapOutlinedIcon />,
    showForSalesChannel: true,
    dataFd: 'btn-order-ingest',
    permissions: 'EditStores',
    tagText: 'Flipdish_staff_only',
    hidden: window.location?.pathname?.includes('/stores'),
  },
  {
    route: `/dangerzone`,
    icon: <Warning />,
    title: 'Danger_zone_title',
    description: 'Danger_zone_description',
    dataFd: 'btn-danger-zone',
    permissions: 'EditStores',
    showForSalesChannel: !linkedSalesChannelId,
  },
];

type Props = MappedProps &
  mapDispatchToProps & {
    linkedSalesChannelId?: string;
  };
const StoreDetails = (props: Props) => {
  const {
    isOwner,
    isStorePublished,
    loading,
    loadingError,
    publishStore,
    routes,
    selectedApp,
    store,
    linkedSalesChannelId,
    notifySavedDispatch,
    notifyErrorDispatch,
  } = props;

  const classes = useStyles();
  const { channelId, propertyId, appId } = useParams<{
    storeId: string;
    channelId: string;
    propertyId?: string;
    appId: string;
  }>();

  const { pathname } = useLocation();
  const { storeId, loadingStoreAssociation } = useLoadStoreFromSalesChannelIdIntoRedux({
    store,
    linkedSalesChannelId,
  });

  const isSalesChannelRoutes = !!channelId;
  const handleSubmit = () => {
    publishStore(Number(storeId), !!store?.Address?.DisplayForCustomer?.trim());
  };

  const salesChannel = useQuerySalesChannelsByParams();
  const queryClient = useQueryClient();

  const { mutateAsync: mutatepublish } = useMutation({
    mutationFn: async () => {
      await SalesChannelService.publishSalesChannel({
        orgId: selectedApp?.OrgId ?? '',
        propertyId: propertyId ?? '',
        salesChannelId: channelId,
      });
    },

    onSuccess: () => {
      notifySavedDispatch();
    },

    onMutate: async () => {
      const previousData = queryClient.getQueryData<{
        data: GetSalesChannelsByOrgIdAndPropertyId200Response;
      }>([getSalesChannelsKey, selectedApp?.OrgId, propertyId]);

      const updatedSalesChannels = [
        ...(previousData?.data?.data?.filter((sc) => sc.salesChannelId !== channelId) || []),
        { ...salesChannel, isPublished: true },
      ];

      const updatedForCache = {
        ...previousData,
        data: { ...previousData?.data, data: updatedSalesChannels },
      };

      queryClient.setQueryData(
        [getSalesChannelsKey, selectedApp?.OrgId, propertyId],
        updatedForCache
      );

      return { previousData };
    },

    onError: (error, variables, context) => {
      notifyErrorDispatch({ message: 'Error_please_try_again_later', translate: true });
      if (context?.previousData) {
        queryClient.setQueryData(
          [getSalesChannelsKey, selectedApp?.OrgId, propertyId],
          context.previousData
        );
      }
    },
  });

  const isRedirecting = useRedirectIfStoresPageHidden(!isSalesChannelRoutes);
  if (isRedirecting === true) {
    return null;
  }

  const handleSalesChannelPublish = async () => {
    await mutatepublish();
  };

  const renderRoutes = routes
    .filter((route) => (isSalesChannelRoutes ? route.showForSalesChannel : true))
    .map((route, index) => {
      return (
        <ListItemLinkButton
          tagText={route.tagText}
          isLast={!!(route.title === 'Order_Ingest' && linkedSalesChannelId)}
          dataFd={route?.dataFd}
          classesFromParent={{
            logo: route.title === 'Danger_zone_title' ? classes.listItemDanger : '',
            primary: route.title === 'Danger_zone_title' ? classes.listItemDanger : '',
            secondary: route.title === 'Danger_zone_title' ? classes.listItemDanger : '',
          }}
          disabled={!!route.comingSoon}
          key={index}
          iconComponent={route.icon}
          title={route.title}
          subtitle={route.description}
          link={`${pathname.endsWith('/') ? pathname.slice(0, -1) : pathname}${route.route}`}
        />
      );
    });

  const redirectUrl = isSalesChannelRoutes
    ? getBackToSalesChannelListUrl({ appId, propertyId, pathname: location?.pathname })
    : `/${selectedApp.AppId}/storegroups`;

  const storeSettings = isSalesChannelRoutes ? (
    <>
      {!salesChannel?.isPublished && !linkedSalesChannelId && (
        <PublishStoreButton
          isOwner={isOwner}
          handleSubmit={handleSalesChannelPublish}
          primaryText="This_sales_channel_isn't_published"
          secondaryText="Ready_to_publish_sales_channel"
          tooltipMessage="Only_an_owner_can_publish_or_unpublish_a_sales_channel"
          buttonText="Publish_sales_channel"
        />
      )}
      {!linkedSalesChannelId && (
        <ListItemLinkButton
          title="General_settings"
          subtitle="Set_name_price_bands_and_menu_View_associated_bran"
          link={`${pathname.endsWith('/') ? pathname.slice(0, -1) : pathname}/edit`}
          iconComponent={<SettingsOutlinedIcon />}
        />
      )}
      {renderRoutes}
    </>
  ) : (
    <PageLayout
      auditLogsFilter={{ type: 'StoreId', value: `${storeId}` }}
      documentTitle="Store"
      title={<StoreTitle />}
      toParent={`/${selectedApp.AppId}/storegroups/${store?.StoreGroupId}`}
      strictToParent
    >
      <PaperContainer fluid>
        <FullWidthContainer>
          <List className={classes.zeroPadding}>
            {!isStorePublished && (
              <PublishStoreButton
                isOwner={isOwner}
                handleSubmit={handleSubmit}
                primaryText="This_store_isn't_published_yet"
                secondaryText="Ready_to_publish_store"
                tooltipMessage="Only_an_owner_can_publish_or_unpublish_a_store"
                buttonText="Publish_store"
              />
            )}
            {renderRoutes}
          </List>
        </FullWidthContainer>
      </PaperContainer>
    </PageLayout>
  );

  const isLoading = loading || loadingStoreAssociation;
  if (!isLoading && channelId && !storeId && !loadingError) {
    return (
      <EmptyComponent
        title="No_store_found_for_sales_channel_channelId"
        titleData={{ channelId }}
        noLink
      />
    );
  }
  return (
    <>
      {loadingError && <Redirect to={redirectUrl} />}
      {isLoading ? <CircularProgress style={{ margin: '10px' }} size={50} /> : storeSettings}
    </>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, ownProps) => {
  const store = getSelectedStore(state);

  const routes = routeData(ownProps?.linkedSalesChannelId);
  let filteredRoutes = routes.filter((route) => !route.hidden);
  if (!state.permissions.includes('ViewAssignedBankAccount')) {
    filteredRoutes = filteredRoutes.filter((route) => route.title !== 'Banking_title');
  }
  if (!state.permissions.includes('FlipdishStaff')) {
    filteredRoutes = filteredRoutes.filter((route) => route.title !== 'Order_Ingest');
  }
  if (!state.permissions.includes('EditStoreKioskSettings')) {
    filteredRoutes = routes.filter((route) => route.title !== 'Store_Kiosk_Settings');
  }
  if (!state.permissions.includes('EditStores')) {
    filteredRoutes = filteredRoutes.filter((route) => route.title !== 'Danger_zone_title');
  }
  const isStorePublished = store?.IsPublished;
  const isOwner = getIsOwner(state);

  return {
    routes: filteredRoutes,
    store,
    selectedApp: state.currentApp,
    loading: initialLoadingSelector(state),
    loadingError: initialLoadingErrorSelector(state),
    isStorePublished,
    isOwner,
  };
};

type mapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    publishStore: (storeId: number, canPublishStore: boolean) => {
      dispatch(storePublish(storeId, canPublishStore));
    },
    notifySavedDispatch: () => {
      dispatch(notifySaved());
    },
    notifyErrorDispatch: ({ message, translate }: { message: string; translate: boolean }) => {
      dispatch(notifyError({ message, translate }));
    },
  };
};

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(StoreDetails);

export { EnhancedComponent as StoreDetails };
