import React from 'react';

import { OpeningHourDispatchTypesEnum } from '@flipdish/orgmanagement';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { weekDaysLocal } from '../../../selectors/storeOpeningHours.selector';
import OpeningHoursField from './OpeningHoursField';

const StyledGridItem = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  },
  display: 'flex',
  alignItems: 'center',
}));

type SectionTitleProps = {
  showLabels: boolean;
};

const SectionTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'showLabels',
})<SectionTitleProps>(({ theme, showLabels }) => ({
  fontSize: '12px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '0.2px',
  color: 'rgba(0, 0, 0, 0.6)',
  paddingBottom: '8px',
  paddingLeft: showLabels ? theme.spacing(1) : theme.spacing(1.5),
  paddingTop: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  },
}));

const FieldsContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

type WeekdayFieldsProps = {
  type: OpeningHourDispatchTypesEnum;
  isLoading?: boolean;
  isSaving?: boolean;
  showLabels?: boolean;
  hideTitle?: boolean;
  title: TranslationId;
};

const WeekdayFields = ({
  type,
  isLoading = false,
  isSaving = false,
  showLabels = true,
  hideTitle = false,
  title,
  translate,
  weekDays,
}: WeekdayFieldsProps & MappedState) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <FieldsContainer>
      <Grid container>
        {!hideTitle && (
          <>
            {showLabels && !isMobile && <Grid item md={2} />}
            <Grid item xs={12} md={10}>
              <SectionTitle showLabels={showLabels}>
                <Translate id={title} />
              </SectionTitle>
            </Grid>
          </>
        )}
        {weekDays.map(({ key }) => (
          <StyledGridItem item xs={12} key={key}>
            <OpeningHoursField
              name={`${type}.${key}`}
              label={translate(`Day_${key}` as TranslationId) as string}
              showLabel={showLabels}
              disabled={isSaving || isLoading}
              isLoading={isLoading}
              placeholder={
                isLoading ? `${translate('Loading')}...` : (translate('Closed') as string)
              }
            />
          </StyledGridItem>
        ))}
      </Grid>
    </FieldsContainer>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => ({
  translate: getTranslate(state),
  weekDays: weekDaysLocal(state),
});

export default connect(mapStateToProps)(WeekdayFields);
