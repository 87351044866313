import React, { useState } from 'react';

import { useAppDispatch } from '@fd/customHooks/useAppDispatch';
import { useAppSelector } from '@fd/customHooks/useAppSelector';
import useQuerySalesChannelsByParams from '@fd/customHooks/useQuerySalesChannelsByParams';
import { App } from '@flipdish/api-client-typescript';
import type {
  GetSalesChannelsByOrgIdAndPropertyId200Response,
  SalesChannel,
} from '@flipdish/orgmanagement';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Permissions from 'react-redux-permissions';
import { useHistory } from 'react-router-dom';

import DangerActionButton from '@fd/ui/molecules/DangerZone/DangerActionButton';
import DangerActionCard from '@fd/ui/molecules/DangerZone/DangerActionCard';
import DangerZoneSimpleActionCard from '@fd/ui/molecules/DangerZone/DangerZoneSimpleActionCard';

import { notifyError, notifySaved } from '../../../layouts/Notify/actions';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import SalesChannelService, { getSalesChannelsKey } from '../../RMS/salesChannels.services';

export type CardData = {
  cardHeader: TranslationId;
  doThisIf: TranslationId[];
  itWillMean: TranslationId[];
  buttonText: TranslationId;
  divider: boolean;
};

const salesChannelCardData = (isFlipdishStaff: boolean): CardData[] => [
  {
    cardHeader: 'Unpublish_this_sales_channel',
    doThisIf: [
      'sales_channel_not_ready_to_accept_orders_yet',
      "You're_closing_for_renovations",
      'You_will_want_to_re-open_sales_channel',
    ],
    itWillMean: [
      "Customers_will_not_be_able_to_order_from_this_sales_channel_while_it's_unpublished",
      'The_sales_channel_will_still_be_visible_in_your_Flipdish_Portal',
    ],
    buttonText: 'Unpublish_sales_channel',
    divider: true,
  },
  {
    cardHeader: 'Archive_this_sales_channel',
    doThisIf: [
      'sales_channel_was_created_in_error_or_closed_permanently',
      "You'll_never_want_to_receive_orders_for_this_sales_channel_in_the_future",
    ],
    itWillMean: [
      'Sales_channel_will_no_longer_be_here_in_your_Flipdish_Portal',
      'Customers_no_longer_order_from_sales_channel_forever',
      'Orders_and_audit_logs_related_to_this_sales_channel_will_be_kept',
    ],
    buttonText: 'Archive_sales_channel',
    divider: isFlipdishStaff,
  },
];

const SalesChannelDangerZone = () => {
  const salesChannel = useQuerySalesChannelsByParams();
  const [isArchived, setIsArchived] = useState<boolean>(salesChannel?.isArchived ?? false);

  const dispatch = useAppDispatch();
  const currentApp = useAppSelector((state) => state.currentApp);
  const isFlipdishStaff = useAppSelector((state) =>
    permissionsSelector.hasPermissionFactory(['FlipdishStaff'])(state)
  );
  const history = useHistory();
  const queryClient = useQueryClient();

  const handleUnPublish = async () => {
    await mutateUnpublish();
  };

  const handleArchive = async () => {
    await mutateArchive();
  };

  const updateCache = ({
    salesChannel,
    isPublished,
    isArchived,
  }: {
    salesChannel: SalesChannel | null;
    isPublished?: boolean;
    isArchived?: boolean;
  }) => {
    const previousData = queryClient.getQueryData<{
      data: GetSalesChannelsByOrgIdAndPropertyId200Response;
    }>([getSalesChannelsKey, currentApp?.OrgId, salesChannel?.propertyId]);

    const updatedSalesChannels = [
      ...(previousData?.data?.data?.filter(
        (sc) => sc.salesChannelId !== salesChannel?.salesChannelId
      ) || []),
      { ...salesChannel, isPublished, isArchived },
    ];

    const updatedForCache = {
      ...previousData,
      data: { ...previousData?.data, data: updatedSalesChannels },
    };

    queryClient.setQueryData(
      [getSalesChannelsKey, currentApp?.OrgId, salesChannel?.propertyId],
      updatedForCache
    );

    return { previousData };
  };

  const { mutateAsync: mutateUnpublish } = useMutation({
    mutationFn: async () => {
      await SalesChannelService.unpublishSalesChannel({
        orgId: currentApp?.OrgId ?? '',
        propertyId: salesChannel?.propertyId ?? '',
        salesChannelId: salesChannel?.salesChannelId ?? '',
      });
    },

    onSuccess: () => {
      dispatch(notifySaved());
    },

    onMutate: async () => {
      return updateCache({
        salesChannel,
        isPublished: false,
        isArchived: salesChannel?.isArchived,
      });
    },

    onError: (error, variables, context) => {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      if (context?.previousData) {
        queryClient.setQueryData(
          [getSalesChannelsKey, currentApp?.OrgId, salesChannel?.propertyId],
          context.previousData
        );
      }
    },
  });

  const { mutateAsync: mutateArchive } = useMutation({
    mutationFn: async () => {
      await SalesChannelService.archiveSalesChannel({
        orgId: currentApp?.OrgId ?? '',
        propertyId: salesChannel?.propertyId ?? '',
        salesChannelId: salesChannel?.salesChannelId ?? '',
      });
    },

    onSuccess: () => {
      history.push(`/${currentApp.AppId}/properties/${salesChannel?.propertyId}`);
    },

    onMutate: async () => {
      return updateCache({
        salesChannel,
        isPublished: salesChannel?.isPublished,
        isArchived: true,
      });
    },

    onError: (error, variables, context) => {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      if (context?.previousData) {
        queryClient.setQueryData(
          [getSalesChannelsKey, currentApp?.OrgId, salesChannel?.propertyId],
          context.previousData
        );
      }
    },
  });

  const { mutateAsync: mutateUnarchive } = useMutation({
    mutationFn: async () => {
      await SalesChannelService.unarchiveSalesChannel({
        orgId: currentApp.OrgId ?? '',
        propertyId: salesChannel?.propertyId ?? '',
        salesChannelId: salesChannel?.salesChannelId ?? '',
      });
    },

    onSuccess: () => {
      setIsArchived(false);
      dispatch(notifySaved());
    },

    onMutate: async () => {
      return updateCache({
        salesChannel,
        isPublished: salesChannel?.isPublished,
        isArchived: false,
      });
    },

    onError: (error, variables, context) => {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));

      if (context?.previousData) {
        queryClient.setQueryData(
          [getSalesChannelsKey, currentApp.OrgId, salesChannel?.propertyId],
          context.previousData
        );
      }
    },
  });

  const getButtonComponent = ({ card }: { card: CardData }) => {
    if (card.cardHeader === 'Unpublish_this_sales_channel') {
      return (
        <Permissions allowed={[App.AppResourceSetEnum.EditStores]}>
          <DangerActionButton
            buttonText={card.buttonText}
            dialogTitle="Unpublish_Sales_channel?"
            dialogDescription="please_type_in_the_name_of_the_sales_channel_to_confirm"
            helperText="Sales_channel_name_does_not_match"
            handleSubmit={handleUnPublish}
            itemName={salesChannel?.name ?? ''}
            isDisabled={!salesChannel?.isPublished}
            tooltipMessage="this_sales_channel_is_already_unpublished"
            introText="If_you_are_sure_you_want_to_unpublish"
            dataFdPrefix="unpublish_sales_channel"
            confirmButtonText="Unpublish"
          />
        </Permissions>
      );
    } else if (card.cardHeader === 'Archive_this_sales_channel') {
      return (
        <Permissions allowed={[App.AppResourceSetEnum.EditStores]}>
          <DangerActionButton
            buttonText={card.buttonText}
            dialogTitle="Archive_sales_channel?"
            dialogDescription="please_type_in_the_name_of_the_sales_channel_to_confirm"
            helperText="Sales_channel_name_does_not_match"
            handleSubmit={handleArchive}
            itemName={salesChannel?.name ?? ''}
            isDisabled={isArchived}
            setIsDisabled={setIsArchived}
            introText="If_you_are_sure_you_want_to_archive"
            dataFdPrefix="archive-sales-channel"
            confirmButtonText="Archive"
          />
        </Permissions>
      );
    }
    return <></>;
  };

  return (
    <>
      {salesChannel &&
        salesChannelCardData(isFlipdishStaff).map((card) => {
          return (
            <DangerActionCard
              cardHeader={card.cardHeader}
              doThisIf={card.doThisIf}
              itWillMean={card.itWillMean}
              divider={card.divider}
              button={getButtonComponent({ card })}
              key={card.cardHeader}
            />
          );
        })}
      {isFlipdishStaff && (
        <DangerZoneSimpleActionCard
          onClick={mutateUnarchive}
          disabled={!isArchived}
          title="Unarchive_this_sales_channel"
          description="This_sales_channel_will_be_visible_and_available"
          buttonText="Unarchive_sales_channel"
          dataFdPrefix="unarchive-sales-channel"
          showStaffTag={true}
        />
      )}
    </>
  );
};

export default SalesChannelDangerZone;
